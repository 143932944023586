<template>
  <div>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- form error template -->
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <!-- Transfer form -->
        <el-form label-position="top" :model="transferForm" ref="transferForm" v-loading="loading">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-form-item
                    label="Нийлүүлэгч байгууллага"
                    prop="supplier_id"
                    :rules="[{ required: true, message: 'Нийлүүлэгч байгууллага сонгоно уу', trigger: 'blur' }]">
                    <el-row :gutter="10" type="flex" align="bottom">
                      <el-col :span="16">
                        <el-select
                          style="width: 100%"
                          v-model="transferForm.supplier_id"
                          @change="setCurrentSupplier"
                          filterable
                          placeholder="Нийлүүлэгч"
                        >
                          <el-option
                            v-for="(item, index) in suppliers"
                            :key="index"
                            :label="item.supplier_monName"
                            :value="item.supplier_id"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="currentSupplier" type="default" class="block" @click="setCurrentSupplier(currentSupplier.supplier_id)">Бүтээгдэхүүн сонгох</el-button>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </div>
                <div class="panel-item">
                  <ul class="transfer-product-list" v-if="transferForm.products.length > 0">
                    <div v-for="(product) in transferForm.products" :key="product.productId">
                      <li v-if="product.variants.length > 0">
                        <el-row :gutter="10" type="flex" align="middle">
                          <el-col :span="2">
                            <div class="image-holder">
                              <img :src="product.image" alt="">
                            </div>
                          </el-col>
                          <el-col :span="20">
                            {{product.name_mon}}
                          </el-col>
                          <el-col :span="2" class="text-right">
                            <i @click="deleteProductFromTransfer(product)" class="el-icon-close cursor-pointer"></i>
                          </el-col>
                        </el-row>
                        <ul>
                          <li v-for="(variant) in product.variants" :key="variant.variant_id">
                            <el-row :gutter="20" type="flex" align="middle">
                              <el-col :span="16" :offset="2">
                                <div>
                                  {{variant.variant_name}}
                                </div>
                                <div class="text-color-secondary">
                                  {{variant.variant_sku}}
                                </div>
                              </el-col>
                              <el-col :span="6">
                                <div class="text-center">
                                  <el-form-item :class="errorArray.filter(item => item.key === variant.variant_sku).length > 0 ? 'is-error': ''">
                                    <el-input
                                      type="number"
                                      min="0"
                                      v-model="variant.number_tobe_transferred"
                                    />
                                  </el-form-item>
                                </div>
                              </el-col>
                              <el-col :span="2" class="text-right">
                                <i @click="deleteVariantFromTransfer(product, variant)" class="el-icon-close cursor-pointer"></i>
                              </el-col>
                            </el-row>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <!-- Product search -->
    <el-dialog
      title="Бүтээгдэхүүн сонгох"
      :visible.sync="visibleProductDialog"
      top="10vh"
      width="500"
    >
      <div>
        <el-input
          placeholder="Барааны нэр, SKU ..."
          v-model="searchText"
        >
          <template slot="prepend"><i class="el-icon-search"></i></template>
        </el-input>
      </div>
      <div class="mt20" style="height:300px">
        <ul class="transfer-product-list" v-if="products.length > 0">
          <li v-for="(product, indexProduct) in products.filter(data => !searchText || data.productId.toString().toLowerCase().includes(searchText.toString().toLowerCase()) || data.name_mon.toString().toLowerCase().includes(searchText.toString().toLowerCase()))" :key="indexProduct">
            <el-row :gutter="10" type="flex" align="middle">
              <el-col :span="2">
                <div class="image-holder">
                  <img :src="product.image" alt="">
                </div>
              </el-col>
              <el-col :span="20">
                {{product.name_mon}}
              </el-col>
              <el-col :span="2" class="text-right">
                <el-checkbox v-model="product.checked" :indeterminate="product.isIndeterminate" @change="checkProduct(product)"></el-checkbox>
              </el-col>
            </el-row>
            <ul>
              <li v-for="(variant, indexVariant) in product.variants" :key="indexVariant">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="18" :offset="2">
                    <div>
                      {{variant.variant_name}}
                    </div>
                    <div class="text-color-secondary">
                      {{variant.variant_sku}}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="text-center">
                      {{variant.availableCount}}
                    </div>
                  </el-col>
                  <el-col :span="2" class="text-right">
                    <el-checkbox v-model="variant.checked" @change="checkVariant(product, variant)"></el-checkbox>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      {{this.products.product}}
        <span slot="footer" class="dialog-footer">
            <el-button @click="visibleProductDialog = false">Cancel</el-button>
            <el-button @click="generate" type="primary" icon="el-icon-printer">Хэвлэх</el-button>
        </span>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <!-- Supplier add form dialog -->
    <supplier-dialog :visibleSupplierDialog="visibleSupplierDialog" :getSuppliers="getSuppliers" :alertReporter="alertReporter" :closeModal="closeModal"/>
  </div>
</template>
<script>
import services from '../../../helpers/services'
// import { login } from '../../pages/user/login.vue'
import SupplierDialog from '../../../components/product/supplierDialog'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { getName } from '../../../utils/auth'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'addTransfer',
  components: {
    SupplierDialog
  },
  data () {
    return {
      userName: '',
      role: '',
      isCollapse: false,
      errorArray: [],
      supplierForm: {
        name: ''
      },
      searchText: '',
      currentSupplier: null,
      confirmLeaveForm: false,
      visibleSupplierDialog: false,
      visibleProductDialog: false,
      suppliers: [],
      products: [],
      transferProducts: [],
      loading: false,
      transferForm: {
        supplier_id: '',
        products: [],
        warehouse_name: 'default',
        warehouse_id: null,
        expected_arrival_at: null
      },
      warehouses: []
    }
  },
  async created () {
    this.getSuppliers()
    this.userName = await getName()
  },
  methods: {
    generate () {
      var docDefinition = {
        content: [
          { style: 'header', text: 'Батлав:Гүйцэтгэх захирал..................../Ч.ДОЛГОРСҮРЭН/', alignment: 'right' },
          { style: 'subheader', text: 'Үнэ батлуулах хуудас', alignment: 'center' },
          ('\n'),
          { style: 'date', alignment: 'right', text: 'Хэвлэсэн огноо: ' + (new Date((new Date()).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')) },
          {
            style: 'tableExample',
            table: {
              // widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              body: [
                [{ text: 'Бүтээгдэхүүн', style: 'tableHeader' }, { text: 'Хувилбар', style: 'tableHeader' }, { text: 'НӨАТ-тай ББӨ', style: 'tableHeader' }, { text: 'НӨАТ-тай худалдах үнэ', style: 'tableHeader' }, { text: 'НӨАТ-гүй ББӨ', style: 'tableHeader' }, { text: 'НӨАТ-гүй худалдах үнэ', style: 'tableHeader' }, { text: 'НӨАТ-гүй бохир ашиг', style: 'tableHeader' }, { text: 'НӨАТ-гүй бохир ашгийн маржин', style: 'tableHeader' }]
              ]
            }
          },
          ('\n'),
          { style: 'header', text: 'Хянасан:', alignment: 'left' },
          { style: 'date', text: 'Борлуулалтын менежер:......................' + (this.userName) },
          { style: 'date', text: 'Ерөнхий нягтлан бодогч:.....................Д.Алтанцэцэг' },
          { style: 'date', text: 'Санхүүгийн менежер:.........................О.Зоригоо' }
        ],
        styles: {
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 10]
          },
          title: {
            fontSize: 18,
            bold: true,
            margin: [-10, 0, 0, 10]
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          status: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 5, 15]
          }
        }
      }
      var tempProducts = []
      this.products.forEach(productElement => {
        if (productElement.variants && productElement.variants.length > 0) {
          productElement.variants.forEach(variantElement => {
            if (variantElement.checked) {
              variantElement.name_mon = productElement.name_mon
              variantElement.name_eng = productElement.name_eng
              tempProducts.push(variantElement)
            }
          })
        }
      })
      if (tempProducts.length > 0) {
        tempProducts.forEach(variant => {
          docDefinition.content[4].table.body.push([{ text: variant.name_mon }, { text: variant.variant_name }, { text: variant.variant_getPrice }, { text: variant.variant_sellPrice }, { text: (variant.variant_getPrice / 1.1).toFixed(2) }, { text: (variant.variant_sellPrice / 1.1).toFixed(2) }, { text: ((variant.variant_sellPrice / 1.1).toFixed(2) - (variant.variant_getPrice / 1.1).toFixed(2)).toFixed(2) }, { text: (((((variant.variant_sellPrice / 1.1).toFixed(2) - (variant.variant_getPrice / 1.1).toFixed(2)).toFixed(2)) * 100) / (variant.variant_sellPrice / 1.1).toFixed(2)).toFixed(2) + '%' }])
        })
        pdfMake.createPdf(docDefinition).print()
      } else {
        this.alertReporter('Уучлаарай', 'Бүтээгдэхүүнээ сонгоно уу!', 'error')
      }
    },
    closeModal () {
      this.visibleSupplierDialog = false
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    getSuppliers () {
      services.getSuppliers('?size=10000').then(response => {
        this.suppliers = response.datas
      })
    },
    setCurrentSupplier (val) {
      this.suppliers.forEach((element) => {
        if (element.supplier_id === val) {
          this.currentSupplier = element
        }
      })
      this.transferForm.supplier_id = this.currentSupplier.supplier_id
      this.getProductsWithVariant()
      this.visibleProductDialog = true
    },
    getProductsWithVariant () {
      services.getProductsWithVariantBySupplierId(this.currentSupplier.supplier_id).then(response => {
      // product check, variants check property add
        response.forEach(product => {
          const property = {
            checked: false,
            isIndeterminate: false
          }
          Object.assign(product, property, product)
          product.variants.forEach(variant => {
            const property = {
              checked: false,
              number_tobe_transferred: 0
            }
            Object.assign(variant, property, variant)
          })
        })
        this.products = response
      })
    },
    checkProduct (product) {
      product.variants.forEach(variant => {
        variant.checked = product.checked
      })
    },
    checkVariant (product, variant) {
      let checkedCount = 0
      product.variants.forEach(variant => {
        if (variant.checked) {
          checkedCount = checkedCount + 1
        }
      })
      product.checked = checkedCount === product.variants.length
      product.isIndeterminate = checkedCount > 0 && checkedCount < product.variants.length
    },
    setTransferProducts () {
      const tempProducts = []
      this.products.forEach(product => {
        const variants = [...product.variants.filter(variant => variant.checked)]
        if (variants.length > 0) {
          product.variants = variants
          tempProducts.push(product)
        }
      })
      if (tempProducts.length > 0) {
        this.transferForm.products = tempProducts
        this.visibleProductDialog = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'bottom-left',
          message: 'Бүтээгдэхүүн сонгоно уу',
          duration: 2000
        })
      }
    },
    deleteVariantFromTransfer (product, delVariant) {
      product.variants = product.variants.filter(variant => variant !== delVariant)
    },
    deleteProductFromTransfer (delProduct) {
      this.transferForm.products = this.transferForm.products.filter(product => product !== delProduct)
    }
  }
}
</script>
<style>
.el-input-group__prepend {
  background-color: #ffffff;
}
.el-table::before {
  content: unset;
}
.el-table tr:last-child td {
  border-bottom: none;
}
</style>
